"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";
import { useWindowSize } from "react-use";
import styles from "./RoomCard.module.scss";
import { ActionButton } from "@/common/buttons/ActionButton/ActionButton";
import { FavoritePictButton } from "@/common/buttons/FavoriteButton/FavoritePictButton";
import { InitialCostScore } from "@/common/icons/InitialCostScore/InitialCostScore";
import Image from "@/common/partials/Image/ImageWithNoImage";
import UpdatePoint from "@/common/partials/UpdatePoint/UpdatePoint";
import PurpleStarsIcon from "@/pages/list/svgs/purpleStars.svg";
import thunderIcon from "public/static/images/icon/thunder-icon.png";
import { GTMEventClient } from "~/clients/google/gtm/GTMEventClient";
import { MIN_PC_WIDTH } from "~/const/window";
import { ConversionPoint } from "~/features/inquiry/enums";
import { previewReservationPath } from "~/features/inquiry/routing-helper";
type RoomCardBuildingProps = {
  id: string;
  name: string;
  address: string;
  stations: {
    full_text: string;
    short_text: string;
  }[];
};
type RoomCardRoomProps = {
  id: string;
  unit_name: string;
  floor_plan: string;
  room_area: string;
  direction_type: string;
  published_within_1week: boolean;
  published_within_1day: boolean;
  time_since_published: string;
  time_since_updated: string;
  thumbnail_image_uri?: string | null | undefined;
  thumbnail_image_is_floor_plan?: boolean;
  free_rent_text?: string | null | undefined;
  initial_cost_score: number;
  vacant_state: string;
  move_in_ready_on: string;
  rent: {
    display_text: string;
  };
  administration_fee: {
    display_text: string;
  };
  security_deposit: {
    is_zero: boolean;
    display_text_relative: string;
  };
  key_money: {
    is_zero: boolean;
    display_text_relative: string;
  };
  broker_fee: {
    is_zero: boolean;
    display_text_relative: string;
  };
};

/**
 * 物件情報の概要が記載されたカードUIのコンポーネント
 */
const RoomCard: React.FunctionComponent<{
  building: RoomCardBuildingProps;
  room: RoomCardRoomProps;
  isRecommendMark?: boolean;
  size?: "default" | "small" | "variable";
  cvPoint?: ConversionPoint | undefined;
}> = ({ building, room, size, isRecommendMark, cvPoint }) => {
  const router = useRouter();
  let sizeClass: string;
  switch (size) {
    case "variable":
      sizeClass = styles.variable;
      break;
    case "small":
      sizeClass = styles.small;
      break;
    default:
      sizeClass = styles.default;
      break;
  }

  const featuresText = [room.floor_plan, room.room_area].join(" / ");

  const { width: windowWidth } = useWindowSize();
  const widthSize = windowWidth;
  const cvButtonText = widthSize < MIN_PC_WIDTH ? "見学予約したい" : "実際に見学したい【無料】";

  return (
    <div className={`${styles.roomCard} ${sizeClass}`}>
      <FavoritePictButton
        roomId={Number(room.id)}
        roomUrl={`${process.env.NEXT_PUBLIC_FRONT_URL}/detail/${building.id}/${room.id}`}
        roomTitle={building.name}
        roomNumber={room.unit_name}
        roomArea={building.address}
        className={styles.favorite}
      ></FavoritePictButton>
      <div className={styles.infoTagWrap}>
        {isRecommendMark && (
          <div>
            <span className={styles.recommend}>
              <PurpleStarsIcon />
              <span>提案</span>
            </span>
          </div>
        )}
        {room.published_within_1week && (
          <div className={styles.newMark}>
            {room.published_within_1day && (
              <Image src={thunderIcon} height={18} width={18} alt="" />
            )}
            <span>{room.time_since_published}</span>
          </div>
        )}
      </div>

      <Link
        className={styles.mainLink}
        href={`/detail/${building.id}/${room.id}`}
        target="_blank"
        prefetch={false}
      >
        <div className={styles.imageWrapper}>
          <Image
            src={room.thumbnail_image_uri}
            fill
            style={{
              objectFit: room.thumbnail_image_is_floor_plan ? "contain" : "cover",
              objectPosition: "center",
            }}
            alt={room.unit_name}
            unoptimized
          ></Image>
        </div>
        <div className={styles.contentWrapper}>
          <p className={styles.price}>
            <span className={styles.priceMain}>{room.rent.display_text}</span>
            <span className={styles.priceSub}>
              <span className="is-pc-only">管理費 {room.administration_fee.display_text}</span>
              <span className="is-sp-only">({room.administration_fee.display_text})</span>
            </span>
          </p>
          <ul className={styles.moneys}>
            <li className={room.security_deposit.is_zero ? styles.isFree : ""}>
              {room.security_deposit.display_text_relative}
            </li>
            <li className={room.key_money.is_zero ? styles.isFree : ""}>
              {room.key_money.display_text_relative}
            </li>
            <li className={room.broker_fee.is_zero ? styles.isFree : ""}>
              {room.broker_fee.display_text_relative}
            </li>
          </ul>
          <p className={styles.text}>
            {room.free_rent_text ? (
              <span className={styles.freerentTag}>{room.free_rent_text}</span>
            ) : null}
          </p>
          {room.initial_cost_score !== 0 && (
            <div className={styles.initialCostWrapper}>
              <InitialCostScore rank={room.initial_cost_score}></InitialCostScore>
            </div>
          )}
          <p className={styles.text}>{featuresText}</p>
          <p className={`${styles.text} is-pc-only`}>{building.stations[0].full_text || "-"}</p>
          <p className={`${styles.text} is-sp-only`}>{building.stations[0]?.short_text || "-"}</p>

          <p className={styles.text}>
            <span className={room.vacant_state === "現在見学可" ? styles.emphasize : ""}>
              {room.vacant_state}
            </span>
            {" / "}
            <span className={room.move_in_ready_on === "即入居可" ? styles.emphasize : ""}>
              {room.move_in_ready_on}
            </span>
          </p>
          <p className={styles.text}>
            <UpdatePoint></UpdatePoint>
            {room.time_since_updated}
          </p>
          {cvPoint && (
            <div>
              <ActionButton
                onClick={(e) => {
                  e?.preventDefault();
                  e?.stopPropagation();
                  router.push(previewReservationPath(room.id, cvPoint) as string);
                  GTMEventClient.beginCheckout();
                }}
                text={cvButtonText}
                type="filled"
                color="cv2"
                size="thin"
                shape="roundedRectangle"
                is3d
                className={styles.actionButton}
              ></ActionButton>
            </div>
          )}
        </div>
      </Link>

      <div className={styles.footer}>
        <Link
          className={styles.footerLink}
          href={`/building/${building.id}`}
          prefetch={false}
          target="_blank"
        >
          <span className={styles.footerLinkIcon}></span>
          <span className={styles.footerLinkText}>{building.name}</span>
        </Link>
      </div>
    </div>
  );
};

export default RoomCard;
