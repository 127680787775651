export const RentConditionHelper = {
  lowerRentOptions: [
    3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5,
    12.0, 12.5, 13.0, 13.5, 14.0, 14.5, 15.0, 15.5, 16.0, 16.5, 17.0, 17.5, 18.0, 18.5, 19.0, 19.5,
    20.0, 21.0, 22.0, 23.0, 24.0, 25.0, 26.0, 27.0, 28.0, 29.0, 30.0, 35.0, 40.0, 50.0, 100.0,
  ],
  upperRentOptions: [
    3.0, 3.5, 4.0, 4.5, 5.0, 5.5, 6.0, 6.5, 7.0, 7.5, 8.0, 8.5, 9.0, 9.5, 10.0, 10.5, 11.0, 11.5,
    12.0, 12.5, 13.0, 13.5, 14.0, 14.5, 15.0, 15.5, 16.0, 16.5, 17.0, 17.5, 18.0, 18.5, 19.0, 19.5,
    20.0, 21.0, 22.0, 23.0, 24.0, 25.0, 26.0, 27.0, 28.0, 29.0, 30.0, 35.0, 40.0, 50.0, 100.0,
  ],
  getText: (lowerRent: string, upperRent: string): string => {
    if (!lowerRent && !upperRent) return "";
    return (
      (lowerRent && parseInt(lowerRent) / 10000 + "万円") +
      "〜" +
      (upperRent && parseInt(upperRent) / 10000 + "万円")
    );
  },
};

type RentConditionProps = {
  lowerRent: string;
  setLowerRent: (lowerRent: string) => void;
  upperRent: string;
  setUpperRent: (upperRent: string) => void;
};

const RentCondition = ({
  lowerRent,
  setLowerRent,
  upperRent,
  setUpperRent,
}: RentConditionProps): JSX.Element => {
  return (
    <>
      <div className={lowerRent ? "sel is-active" : "sel"}>
        <select
          name="lowerRent"
          id="lowerRent"
          value={lowerRent}
          onChange={(e) => setLowerRent(e.target.value)}
        >
          <option value="">下限なし</option>
          {RentConditionHelper.lowerRentOptions.map((option) => (
            <option value={option * 10000} key={`lowerRent_${option}`}>
              {option}万円
            </option>
          ))}
        </select>
      </div>
      <p>〜</p>
      <div className={upperRent ? "sel is-active" : "sel"}>
        <select
          name="upperRent"
          id="upperRent"
          value={upperRent}
          onChange={(e) => setUpperRent(e.target.value)}
        >
          <option value="">上限なし</option>
          {RentConditionHelper.upperRentOptions.map((option) => (
            <option value={option * 10000} key={`upperRent_${option}`}>
              {option}万円
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default RentCondition;
