import TagManager from "react-gtm-module";

type GTMEventProps = {
  clickFavorite: {
    category: "favorite";
    action: "add" | "remove";
    label: string;
    room_id: string;
    url: string;
    room_title: string;
    room_number: string;
    room_area: string;
  };
  saveSearchCondition: { url: string };
  search: Record<string, never>;
  conversion: Record<string, never>;
  beginCheckout: Record<string, never>;
  clickTel: { phoneNumber: string };
  searchWithSavedCondition: Record<string, never>;
};

export const GTMEventClient = {
  sendEvent: <T extends keyof GTMEventProps>(eventName: T, eventProps?: GTMEventProps[T]) => {
    TagManager.dataLayer({
      dataLayer: { event: eventName, ...eventProps },
    });
  },

  clickFavorite: (
    roomId: string,
    roomUrl: string,
    roomTitle: string,
    roomNumber: string,
    roomArea: string,
    isFavorite: boolean,
    currentUrl: string,
  ) =>
    GTMEventClient.sendEvent("clickFavorite", {
      category: "favorite",
      action: isFavorite ? "add" : "remove",
      label: currentUrl,
      room_id: roomId,
      url: roomUrl,
      room_title: roomTitle,
      room_number: roomNumber,
      room_area: roomArea,
    }),

  saveSearchCondition: (url: string) => GTMEventClient.sendEvent("saveSearchCondition", { url }),

  search: () => GTMEventClient.sendEvent("search"),

  conversion: () => GTMEventClient.sendEvent("conversion"),

  beginCheckout: () => GTMEventClient.sendEvent("beginCheckout"),

  clickTel: (phoneNumber: string) => GTMEventClient.sendEvent("clickTel", { phoneNumber }),

  searchWithSavedCondition: () => GTMEventClient.sendEvent("searchWithSavedCondition"),
};
