import { airdoor, RailwayData } from "@airdoor/master-data";
import Line from "../route/LineType";
import { makeStation, Station } from "./Station";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Railway = {
  id: number;
  displayName: string;
  url: string | undefined;
  legacyCode: string | undefined;
  queryKey: string;
  query: { [key: string]: string } | undefined;
  displayOrder: number;
  stationIds: number[];
  stations: Station[];
};
export type IndexableRailway = Railway & { query: { [key: string]: string }; url: string };

export const generateRailway = (li: Line): { displayName: string; url: string } => {
  return {
    displayName: li.display_name,
    url: "/list?li=d-" + li.id,
  };
};
export const getRailway = (railwayId: number): Railway | undefined => {
  try {
    const railway = airdoor.railways.get(railwayId);
    return makeRailway(railway);
  } catch (e) {
    // 登録のない沿線の可能性があるので
  }
};
export const getRailwayByLegacyCode = (legacyCode: string): Railway => {
  const railway = airdoor.legacyRailways.get(legacyCode);

  return makeRailway(railway, legacyCode);
};

/**
 * @param stationIds
 * @param prefectureSlug 指定すると特定の沿線ないの駅のみに絞り込み可能
 * @returns
 */
export const getFulfilledRailways = (stationIds: number[], jisX_0401?: string): Railway[] => {
  if (stationIds.length < 1) return [];

  const railways = airdoor.prefectures
    .all()
    .filter((p) => !jisX_0401 || p.jisX_0401 === jisX_0401)
    .flatMap((p) => p.railwayCompanies.flatMap((c) => c.railways));

  // 都道府県指定のある場合は、該当都道府県に含まれる駅のみで判定する
  return railways
    .filter((r) => {
      const stations = r.stations.filter(
        (s) => !jisX_0401 || s.jisX_0401_0402.startsWith(jisX_0401),
      );
      return stations.length > 0 && stations.every((s) => stationIds.includes(s.id));
    })
    .map((r) => makeRailway(r));
};

export const makeRailway = (data: RailwayData, legacyCode?: string) => {
  const legacyRailwayCode = legacyCode || airdoor.legacyRailways.getLegacyCode(data.id);

  return {
    id: data.id,
    displayName: data.name,
    legacyCode: legacyRailwayCode,
    displayOrder: data.displayOrder,
    stationIds: data.stationIds,
    queryKey: QUERY_KEY.LINE,
    get query() {
      if (!this.legacyCode) return;
      return { [this.queryKey]: `d-${this.legacyCode}` };
    },
    get url() {
      if (!this.legacyCode) return;
      return `/condition/route/${this.legacyCode}`;
    },
    get stations() {
      return data.stations.map((s) => makeStation(s));
    },
  };
};
