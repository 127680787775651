/**
 * デバイスタイプ
 */
export const DeviceType = {
  PC: "pc",
  SP: "sp",
} as const;

export type DeviceType = (typeof DeviceType)[keyof typeof DeviceType];

export const deviceTypeLabels = {
  [DeviceType.PC]: "PC",
  [DeviceType.SP]: "SP",
} as const;
