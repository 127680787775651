import ShikugunGroup from "./ShikugunGroupType";
import Shikugun from "./ShikugunType";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";
import { TODOFUKEN_TABLE } from "~/data/TODOFUKEN_TABLE";

type TodofukenProps = {
  id: string;
  display_name: string;
  slug: string;
  is_active: boolean; // 現在はtrue固定だが、マスタ運用をし始めるとfalseが必要になる見込み
};

export default class Todofuken {
  private readonly _id: string;
  private readonly _slug: string;
  private readonly _display_name: string;

  // 初回生成時には作成しない変数
  private _shikugun_groups: ShikugunGroup[] | undefined;
  private _shikuguns: Shikugun[] | undefined;

  constructor(props: TodofukenProps) {
    this._id = props.id;
    this._slug = props.slug;
    this._display_name = props.display_name;
  }

  get id(): string {
    return this._id;
  }

  get display_name(): string {
    return this._display_name;
  }

  get shikugun_groups(): ShikugunGroup[] {
    let otherGroup;
    if (!this._shikugun_groups) {
      otherGroup = new ShikugunGroup({
        id: null,
        display_name: `${this.display_name} そのほかの地域`,
        short_display_name: null,
        slug: `${this._slug}_other`,
        todofuken_id: this._id,
      });
      this._shikugun_groups = ShikugunGroup.findByTodofukenID(this.id);
    }
    return [...this._shikugun_groups, otherGroup];
  }

  get shikuguns(): Shikugun[] {
    if (!this._shikuguns) {
      this._shikuguns = Shikugun.findActiveByTodofukenId(this.id);
    }
    return this._shikuguns;
  }

  get url(): string {
    return `/list?${QUERY_KEY.TODOFUKEN}=d-${this.id}`;
  }

  /*
   * FIXME: API化した際のリプレイス対象ここから
   */
  static activeAll(): Todofuken[] {
    return TODOFUKEN_TABLE.filter((data) => data.is_active === true).map(
      (data) => new Todofuken(data),
    );
  }

  static activeAllIds(): string[] {
    return TODOFUKEN_TABLE.filter((d) => d.is_active).map((d) => d.id);
  }

  static find(id: string): Todofuken | undefined {
    const target = TODOFUKEN_TABLE.find((data) => data.id === id);
    return target ? new Todofuken(target) : undefined;
  }

  static findStrictly(id: string): Todofuken {
    const target = this.find(id);
    if (!target) {
      throw new TypeError(`todofukenId ${id} is invalid.`);
    }
    return target;
  }

  static isActiveById(id: string): boolean {
    return !!TODOFUKEN_TABLE.find((data) => data.id === id && data.is_active);
  }

  /*
   * API化した際のリプレイス対象ここまで
   */

  /*
   * FIXME: ID検索化した際のリプレイス対象
   * ※ リプレイスしやすいように、find_by_nameは作らない
   */
  static convertNameToId(display_name: string): string | undefined {
    return TODOFUKEN_TABLE.find((data) => data.display_name === display_name)?.id;
  }
}
