"use client";

import { usePathname } from "next/navigation";
import { ReactNode, useContext, useEffect, useState } from "react";
import { useWindowSize } from "react-use";
import styles from "./Layout.module.scss";
import SurveyModal from "@/common/modals/SurveyModal";
import AppStoreBannerForTop from "@/layouts//AppStoreBannerForTop/AppStoreBannerForTop";
import AppStoreBanner from "@/layouts/AppStoreBanner/AppStoreBanner";
import Notification from "@/layouts/Common/Notification";
import Footer from "@/layouts/Footer/Footer";
import Header from "@/layouts/Header/Header";
import { LOCAL_STORAGE_KEYS } from "~/const/localStorage";
import { MIN_PC_WIDTH } from "~/const/window";
import { SurveyPopupContext } from "~/contexts";

const Layout: React.FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const { surveyPopupIsOpen, setSurveyPopupIsOpen } = useContext(SurveyPopupContext);
  const { width: windowWidth } = useWindowSize();
  const widthSize = windowWidth;
  const pathname = usePathname();
  const isPC = widthSize > MIN_PC_WIDTH;
  const [appBannerIsOpen, setAppBannerIsOpen] = useState(false);
  const [appBannerIsOpenForTop, setAppBannerIsOpenForTop] = useState(false);

  useEffect(() => {
    // トップには専用のバナーを表示させる
    if (pathname === "/") {
      setAppBannerIsOpen(false);
      const isHideAppBannerFlagForTop = localStorage.getItem(
        LOCAL_STORAGE_KEYS.IS_HIDE_APP_BANNER_FOR_TOP,
      );
      setAppBannerIsOpenForTop(isHideAppBannerFlagForTop !== "true" && !isPC);
      return;
    }
    setAppBannerIsOpenForTop(false);
    const isHideAppBannerFlag = localStorage.getItem(LOCAL_STORAGE_KEYS.IS_HIDE_APP_BANNER);
    setAppBannerIsOpen(isHideAppBannerFlag !== "true" && !isPC);
  }, [isPC, pathname]);

  const scrollTop = () => {
    const duration = 150;
    const currentY = window.pageYOffset;
    const step = duration / currentY > 1 ? 10 : 100;
    const timeStep = (duration / currentY) * step;
    const intervalId = setInterval(scrollUp, timeStep);

    function scrollUp() {
      const currentY = window.pageYOffset;
      if (currentY === 0) {
        clearInterval(intervalId);
      } else {
        scrollBy(0, -step);
      }
    }
  };

  const updateIsHideAppBannerFroTop = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_HIDE_APP_BANNER_FOR_TOP, "true");
    setAppBannerIsOpenForTop(false);
  };

  const updateIsHideAppBanner = () => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.IS_HIDE_APP_BANNER, "true");
    setAppBannerIsOpen(false);
  };

  return (
    <>
      {process.env.NEXT_PUBLIC_ENV_NAME == "development" && (
        <div
          className={`${styles.env} ${styles.dev}`}
          onClick={(e) => {
            const t = e.target as HTMLElement;
            t.style.display = "none";
          }}
        >
          開発環境
        </div>
      )}
      {process.env.NEXT_PUBLIC_ENV_NAME == "local" && (
        <div
          className={`${styles.env} ${styles.local}`}
          onClick={(e) => {
            const t = e.target as HTMLElement;
            t.style.display = "none";
          }}
        >
          ローカル環境
        </div>
      )}
      <div className={styles.root}>
        <Header appBannerIsOpen={appBannerIsOpen} />
        {appBannerIsOpen && <AppStoreBanner updateIsHideAppBanner={updateIsHideAppBanner} />}

        <div className={`${styles.body} l-basic`}>
          <Notification />
          {children}
        </div>
        <Footer isMorePaddingBottom={appBannerIsOpenForTop} />
        <div className={styles.scrollTop} onClick={scrollTop}></div>
      </div>
      {surveyPopupIsOpen && (
        <SurveyModal isOpen={surveyPopupIsOpen} setIsOpen={setSurveyPopupIsOpen} />
      )}
      {appBannerIsOpenForTop && (
        <div className="is-sp-only">
          <AppStoreBannerForTop updateIsHideAppBanner={updateIsHideAppBannerFroTop} />
        </div>
      )}
    </>
  );
};

export default Layout;
