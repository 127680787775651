import _ from "lodash";
import { CategoryConditionHelper } from "../../Condition/CategoryCondition";
import { ConstructionConditionHelper } from "../../Condition/ConstructionCondition";
import { ExclusiveAreaConditionHelper } from "../../Condition/ExclusiveAreaCondition";
import { FloorPlanConditionHelper } from "../../Condition/FloorPlanCondition";
import { RentConditionHelper } from "../../Condition/RentCondition";
import { RegionConditionInterface } from "./Abstract/AbstractRegionCondition";
import { RouteConditionInterface } from "./Abstract/AbstractRouteCondition";
import Conditions from "./Conditions";

const DELIMITER = ", ";

export class ConditionLabel {
  readonly _conditionText: {
    articleTitle: string | undefined;
    articleName: string | undefined;
    keyword: string;
    route: string | undefined;
    region: string | undefined;
    location: string;
    initialCostScore: string;
    lowerRent: string;
    upperRent: string;
    rentAdministrationText: string | undefined;
    rentText: string | undefined;
    upperFoot: string;
    lowerArea: string;
    upperArea: string;
    exclusiveAreaText: string;
    lowerBuildingAge: string;
    upperBuildingAge: string;
    floorPlan: string;
    floorPlanTitle: string;
    construction: string;
    category: string;
    categoryTitle: string;
  };

  constructor(private readonly _conditions: Conditions) {
    const {
      selectedArticle,
      keywordCondition,
      lowerInitialCostScoreCondition,
      lowerRentCondition,
      upperRentCondition,
      upperFootCondition,
      lowerAreaCondition,
      upperAreaCondition,
      floorPlanCondition,
      lowerBuildingAgeCondition,
      upperBuildingAgeCondition,
      constructionCondition,
      categoriesCondition,
    } = _conditions;

    const rentText = RentConditionHelper.getText(
      this._conditions.lowerRentCondition,
      this._conditions.upperRentCondition,
    );
    const route = this.routeText(_conditions.routeCondition);
    const region = this.areaText(_conditions.regionCondition);

    this._conditionText = {
      articleTitle: selectedArticle?.seo_title,
      articleName: selectedArticle?.display_name,
      keyword: keywordCondition && `"${keywordCondition}"を含む`,
      route,
      region,
      location: [route, region].filter(Boolean).join("／"),
      initialCostScore:
        lowerInitialCostScoreCondition && `初期トク${parseInt(lowerInitialCostScoreCondition)}以上`,
      lowerRent: lowerRentCondition && parseInt(lowerRentCondition) / 10000 + "万円以上",
      upperRent: upperRentCondition && parseInt(upperRentCondition) / 10000 + "万円以下",
      rentAdministrationText: this._conditions.includeAdministrationFeeCondition
        ? rentText
        : undefined,
      rentText: this._conditions.includeAdministrationFeeCondition ? undefined : rentText,
      upperFoot: upperFootCondition && parseInt(upperFootCondition) + "分以内",
      lowerArea: lowerAreaCondition && lowerAreaCondition + "㎡以上",
      upperArea: upperAreaCondition && upperAreaCondition + "㎡以下",
      exclusiveAreaText: ExclusiveAreaConditionHelper.getText(
        lowerAreaCondition,
        upperAreaCondition,
      ),
      lowerBuildingAge: lowerBuildingAgeCondition && "築" + lowerBuildingAgeCondition + "年以上",
      upperBuildingAge: upperBuildingAgeCondition && "築" + upperBuildingAgeCondition + "年以下",
      floorPlan: FloorPlanConditionHelper.getText(floorPlanCondition),
      floorPlanTitle: FloorPlanConditionHelper.getTitle(floorPlanCondition),
      construction: ConstructionConditionHelper.getText(constructionCondition),
      category: CategoryConditionHelper.getText(categoriesCondition),
      categoryTitle: CategoryConditionHelper.getTitle(categoriesCondition),
    };
  }

  /**
   * 「現在の検索条件」に表示するテキスト
   *
   * 検索条件の表示順に合わせて、下記の順番に結合する。
   * - キーワード
   * - エリア
   * - 初期トク
   * - 家賃
   * - 徒歩分
   * - 面積
   * - 築年数
   * - 間取り
   * - 構造
   * - カテゴリ
   */
  get currentConditionText(): string {
    return this.conditionText();
  }

  /**
   * SPの「詳細条件」に表示するテキスト
   * 上記からlocationTextを抜いたもの
   */
  get currentCategoryConditionText(): string {
    return this.conditionText({ location: false });
  }

  /**
   * 賃貸の前につけるprefixの生成（tintaiPrefix）
   *
   * 複雑な条件変動に応じて変更するため、tintaiPrefixは下記2つの変数で構成する
   * 1. Primary prefix
   * 2. Secondary prefix
   *
   * 1. Primary prefix:
   * 都道府県 > 市区町村 > 沿線 > 駅 > 特集リスト条件 > 間取り > 家賃 > それ以外の条件
   *
   * 2. Secondary prefix:
   * エリア条件かそれ以外の条件が2つ以上選択されているとき、「他」
   * エリア条件が1つだけ選択されているとき、「こだわり条件 > 間取り > 家賃 > それ以外の条件」の順番で付与
   * それ以外の条件が1つも選択されていなければ、その他の条件または「すべて」
   * そうでなければ（3条件が1つ選択されていれば）空
   *
   */
  get tintaiPrefix(): string {
    const primaryPrefix = this.primaryPrefix();
    const secondaryPrefix = this.secondaryPrefix();

    return [primaryPrefix, secondaryPrefix].filter((v) => !!v).join(" ");
  }

  get savedCondition(): { [name: string]: string } {
    const conds = {
      キーワード: this._conditionText.keyword,
      エリア: this._conditionText.region,
      "沿線/駅": this._conditionText.route,
      初期トク: this._conditionText.initialCostScore,
      "家賃+管理費": this._conditionText.rentAdministrationText,
      家賃: this._conditionText.rentText,
      駅徒歩: this._conditionText.upperFoot,
      面積: this._conditionText.exclusiveAreaText,
      築年数: this._conditionText.upperBuildingAge,
      構造: this._conditionText.construction,
      間取り: this._conditionText.floorPlan,
      こだわり条件: this._conditionText.category,
      // "並び順": orderList[order]; 並び順は保存しない。常に新着順を表示する
    };

    const result: { [name: string]: string } = {};
    for (const key in conds) {
      const value = conds[key as keyof typeof conds];
      if (value !== undefined && !!value) {
        result[key] = value as string; // 型を明示
      }
    }

    return result;
  }

  get locationText(): string {
    return this._conditionText.location;
  }

  get initialCostScoreText(): string {
    return this._conditionText.initialCostScore;
  }

  get otherCrumbText(): string | undefined {
    return [
      this._conditionText.articleTitle,
      this._conditionText.floorPlanTitle,
      this._conditionText.initialCostScore,
      this._conditionText.lowerRent,
      this._conditionText.upperRent,
      this._conditionText.upperFoot,
      this._conditionText.lowerArea,
      this._conditionText.upperArea,
      this._conditionText.lowerBuildingAge,
      this._conditionText.upperBuildingAge,
      this._conditionText.construction,
      this._conditionText.keyword,
    ].filter((text) => !!text)[0];
  }

  private conditionText(exclude?: { location: boolean }): string {
    return (
      [
        this._conditionText.keyword,
        exclude?.location ? undefined : this._conditionText.location,
        this._conditionText.initialCostScore,
        this._conditionText.lowerRent,
        this._conditionText.upperRent,
        this._conditionText.upperFoot,
        this._conditionText.lowerArea,
        this._conditionText.upperArea,
        this._conditionText.lowerBuildingAge,
        this._conditionText.upperBuildingAge,
        this._conditionText.floorPlan,
        this._conditionText.construction,
        this._conditionText.category,
      ]
        .filter((c) => !!c)
        .join(", ") || "指定なし"
    );
  }

  private primaryPrefix(): string {
    return (
      [
        this._conditionText.region,
        this._conditionText.route,
        this._conditionText.articleTitle,
        this._conditionText.floorPlanTitle,
        this._conditionText.initialCostScore,
        this._conditionText.lowerRent,
        this._conditionText.upperRent,
        this._conditionText.upperFoot,
        this._conditionText.lowerArea,
        this._conditionText.upperArea,
        this._conditionText.lowerBuildingAge,
        this._conditionText.upperBuildingAge,
        this._conditionText.construction,
        this._conditionText.categoryTitle,
        this._conditionText.keyword,
      ].reduce((pre, cur) => (cur && !pre ? cur : pre), "") || "すべて"
    );
  }

  private secondaryPrefix(): string {
    if (
      this._conditions.selectedAreaConditionLength > 1 ||
      this._conditions.selectedOtherConditionLength > 1
    )
      return "他";

    if (this._conditions.selectedAreaConditionLength !== 1) {
      return "";
    }

    return (
      [
        this._conditionText.articleTitle,
        this._conditionText.floorPlanTitle,
        this._conditionText.initialCostScore,
        this._conditionText.lowerRent,
        this._conditionText.upperRent,
        this._conditionText.upperFoot,
        this._conditionText.lowerArea,
        this._conditionText.upperArea,
        this._conditionText.lowerBuildingAge,
        this._conditionText.upperBuildingAge,
        this._conditionText.construction,
        this._conditionText.categoryTitle,
        this._conditionText.keyword,
      ].reduce((pre, cur) => (cur && !pre ? cur : pre), "") || ""
    );
  }

  /**
   * 下記、各項目の文言
   */

  private routeText(condition: RouteConditionInterface): string | undefined {
    if (!condition.hasAnyCondition) return;

    return [...condition.optimizedData.railways, ...condition.optimizedData.stations]
      .map(display)
      .join(DELIMITER);
  }

  private areaText(condition: RegionConditionInterface): string | undefined {
    if (!condition.hasAnyCondition) return;

    const groups = [
      ...condition.optimizedData.queryableGroups,
      ...condition.optimizedData.notQueryableGroups,
    ];

    return [
      ...condition.optimizedData.prefectures,
      ..._.sortBy(groups, ["jisX_0401", "id"]),
      ...condition.optimizedData.municipalities,
    ]
      .map(display)
      .join(DELIMITER);
  }
}

const display = (v: { displayName: string }) => v.displayName;
