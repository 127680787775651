import Image from "next/image";
import { FC } from "react";
import styles from "./SwiperLogos.module.scss";
import companies1PC from "/public/static/images/top-images/swiperImages/companies-1-pc.png";
import companies2PC from "/public/static/images/top-images/swiperImages/companies-2-pc.png";
import companies3PC from "/public/static/images/top-images/swiperImages/companies-3-pc.png";
import companies1SP from "/public/static/images/top-images/swiperImages/companies-1-sp.png";
import companies2SP from "/public/static/images/top-images/swiperImages/companies-2-sp.png";
import companies3SP from "/public/static/images/top-images/swiperImages/companies-3-sp.png";

const companyImagesPC = [
  { src: companies1PC, width: 1861, height: 60 },
  { src: companies2PC, width: 1962, height: 60 },
  { src: companies3PC, width: 733, height: 60 },
];

const companyImagesSP = [
  { src: companies1SP, width: 1396, height: 40 },
  { src: companies2SP, width: 1471, height: 40 },
  { src: companies3SP, width: 550, height: 40 },
];

const SwiperLogos: FC = () => {
  return (
    <>
      <div className={`${styles.swiperItems} is-pc-only`}>
        <div className={styles.slider}>
          {companyImagesPC.map((image, index) => (
            <Image
              key={index}
              src={image.src}
              width={image.width}
              height={image.height}
              alt=""
              unoptimized
            />
          ))}
          {/* 最初の画像を複製してループを実現 */}
          <Image
            src={companyImagesPC[0].src}
            width={companyImagesPC[0].width}
            height={companyImagesPC[0].height}
            alt=""
            unoptimized
          />
        </div>
      </div>

      <div className={`${styles.swiperItems} is-mt10 is-sp-only`}>
        <div className={styles.slider}>
          {companyImagesSP.map((image, index) => (
            <Image
              key={index}
              src={image.src}
              width={image.width}
              height={image.height}
              alt=""
              unoptimized
            />
          ))}
          {/* 最初の画像を複製してループを実現 */}
          <Image
            src={companyImagesSP[0].src}
            width={companyImagesSP[0].width}
            height={companyImagesSP[0].height}
            alt=""
            unoptimized
          />
        </div>
      </div>
    </>
  );
};

export default SwiperLogos;
