/**
 * リストページで使用するクエリパラメータのキー
 *
 * * エリア系のキー > その他条件キーの順序に並べている
 */
export const QUERY_KEY = Object.freeze({
  LINE: "li",
  RAILWAY_ID: "rid",
  STATION: "st",
  STATION_ID: "sid",
  TODOFUKEN: "to",
  SHIKUGUN: "si",
  SHIKUGUN_GROUP: "sg",
  PREFECTURE_SLUG: "prf",
  MUNICIPALITY_ID: "mid",
  JIS_X_0401_0402: "jis",
  KEYWORD: "k",
  INITIAL_COST_SCORE: "ics",
  LOWER_RENT: "lr",
  UPPER_RENT: "ur",
  INCLUDE_ADMIN_FREE: "iaf",
  UPPER_FOOT: "uf",
  LOWER_AREA: "le",
  UPPER_AREA: "ue",
  LOWER_BUILDING_AGE: "la",
  UPPER_BUILDING_AGE: "ua",
  FLOOR_PLAN: "fp",
  CONSTRUCTION: "cs",
  CATEGORY: "ca",
  ORDER: "o",
  ARTICLE: "a_id",
  PAGE: "p",
  PROPERTY_AGENT_NAME: "prp",
});
