import { ConversionPoint } from "~/features/inquiry/enums";

/** 見学予約ページのパス */
export const previewReservationPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("preview-reservation", roomId, cvPoint);
/** 見学予約完了ページのパス */
export const previewReservationCompletePath = (): string =>
  inquiryCompletePagePath("preview-reservation");

/** 来店予約ページのパス */
export const visitReservationPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("visit-reservation", roomId, cvPoint);
/** 来店予約完了ページのパス */
export const visitReservationCompletePath = (): string =>
  inquiryCompletePagePath("visit-reservation");

/** オンライン予約ページのパス */
export const onlineReservationPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("online-reservation", roomId, cvPoint);
/** オンライン予約完了ページのパス */
export const onlineReservationCompletePath = (): string =>
  inquiryCompletePagePath("online-reservation");

/** 来店orオンライン予約ページのパス */
export const visitOrOnlineReservationPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("visit-or-online-reservation", roomId, cvPoint);
/** 来店orオンライン予約完了ページのパス */
export const visitOrOnlineReservationCompletePath = (): string =>
  inquiryCompletePagePath("visit-or-online-reservation");

/** 入居申込ページのパス */
export const rentalApplicationPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("rental-application", roomId, cvPoint);
/** 入居申込完了ページのパス */
export const rentalApplicationCompletePath = (): string =>
  inquiryCompletePagePath("rental-application");

/** その他問い合わせページのパス */
export const otherInquiryPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("other", roomId, cvPoint);
/** その他問い合わせ完了ページのパス */
export const otherInquiryCompletePath = (): string => inquiryCompletePagePath("other");

/** LINE問い合わせページのパス */
export const lineInquiryPath = (roomId: string, cvPoint: ConversionPoint): string =>
  inquiryPagePath("line", roomId, cvPoint);
/** LINE問い合わせ完了ページのパス */
export const lineInquiryCompletePath = (): string => inquiryCompletePagePath("line");

/**　掲載外の物件相談完了ページのパス */
export const noListedRequestCompletePath = (): string =>
  inquiryCompletePagePath("no-listed-request");

/** 物件提案希望完了ページのパス */
export const consultationRequestCompletePath = (): string =>
  inquiryCompletePagePath("consultation-request");

const inquiryPagePath = (
  type:
    | "preview-reservation"
    | "visit-reservation"
    | "online-reservation"
    | "visit-or-online-reservation"
    | "rental-application"
    | "other"
    | "line",
  roomId: string,
  cvPoint: ConversionPoint,
): string => `/inquiry/${type}/${roomId}?cvp=${cvPoint}`;

const inquiryCompletePagePath = (
  type:
    | "preview-reservation"
    | "visit-reservation"
    | "online-reservation"
    | "visit-or-online-reservation"
    | "rental-application"
    | "other"
    | "line"
    | "no-listed-request"
    | "consultation-request",
): string => `/inquiry/${type}/thankyou`;
