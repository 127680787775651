import { ParsedUrlQuery } from "querystring";
import _ from "lodash";
import { ConditionInterface } from "../ConditionInterface";
import { getFulfilledRailways, Railway } from "~/types/Models/Railway";
import { Station } from "~/types/Models/Station";

type RouteConditionType = {
  railways: Railway[];
  stations: Station[];
};
export interface RouteConditionInterface extends ConditionInterface {
  optimizedData: RouteConditionType;
  isValidQuery: boolean;
  isCanonical: boolean;
  query: ParsedUrlQuery;
  canonicalQuery: ParsedUrlQuery;
}
export abstract class AbstractRouteCondition implements RouteConditionInterface {
  abstract legacy: boolean;
  protected _sourceData: RouteConditionType;
  protected abstract setSourceData(): void;
  protected abstract toQuery(data: RouteConditionType): ParsedUrlQuery;

  private _optimizedData: RouteConditionType;
  get optimizedData(): RouteConditionType {
    if (!this._optimizedData) this.setOptimizedData();
    return this._optimizedData;
  }

  private setOptimizedData(): void {
    const fulfilledRailways = getFulfilledRailways(this.sourceData.stations.map((s) => s.id));
    // ・queryが存在しない駅は除外する
    const optimizedRailways = _.uniqBy(
      [...this.sourceData.railways, ...fulfilledRailways].filter((s) => !!s.query),
      "id",
    );
    // ・沿線条件に設定のある駅は除外する
    // ・queryが存在しない駅は除外する
    const optimizedStations = _.uniqBy(
      this.sourceData.stations
        .filter((s) => !optimizedRailways.some((r) => r.id === s.railwayId))
        .filter((s) => !!s.query),
      "id",
    );

    this._optimizedData = {
      railways: _.sortBy(optimizedRailways, "id"),
      stations: _.sortBy(optimizedStations, ["railwayId", "id"]), // FIXME: 修正する see: https://www.notion.so/airdoor/stations-display_order-1843e1e1116980d5bfbed1de4852cf4d
    };
  }

  get isCanonical(): boolean {
    if (this.optimizedData.stations.length < 1) return true;
    return this.optimizedData.stations.every((s) => s.canonicalStation.id === s.id);
  }

  get isValidQuery(): boolean {
    return (
      this.sourceData.railways.every((r) => r.query !== undefined) &&
      this.sourceData.stations.every((s) => s.query !== undefined)
    );
  }

  get hasAnyCondition(): boolean {
    return this.variantCount > 0;
  }

  get variantCount(): number {
    let count = 0;
    this.optimizedData.railways.length > 0 && ++count;
    this.optimizedData.stations.length > 0 && ++count;

    return count;
  }

  get conditionCount(): number {
    return this.optimizedData.railways.length + this.optimizedData.stations.length;
  }

  get query(): ParsedUrlQuery {
    return this.toQuery(this.optimizedData);
  }

  get canonicalQuery(): ParsedUrlQuery {
    return this.toQuery(this.canonicalData);
  }

  private get canonicalData(): RouteConditionType {
    const data = {
      ...this.optimizedData,
    };

    data.stations = _.uniqBy(
      data.stations.map((s) => s.canonicalStation),
      "id",
    );

    return data;
  }

  private get sourceData(): RouteConditionType {
    if (!this._sourceData) this.setSourceData();
    return this._sourceData;
  }
}
