// API化した場合における、市区郡カテゴリテーブルに該当

import { Todofuken } from "~/data/TODOFUKEN_TABLE";

export type ShikugunGroupSchema = {
  id: string; // マスタでは jis x 0402 を使用しておりSEOの懸念があるがどこかで統一したい
  display_name: string;
  short_display_name: string | null;
  slug: string;
  todofuken_id: string;
};

export enum ShikugunGroup {
  Tokyo23ku = "131000",
  Yokohama = "141003",
  Kawasaki = "141305",
  Sagamihara = "141500",
}

export const SHIKUGUN_GROUP_TABLE: ShikugunGroupSchema[] = [
  {
    id: ShikugunGroup.Tokyo23ku,
    display_name: "東京都 23区",
    short_display_name: "東京23区",
    slug: "23ku",
    todofuken_id: Todofuken.Tokyo,
  },
  {
    id: ShikugunGroup.Yokohama,
    display_name: "横浜市",
    short_display_name: null,
    slug: "yokohama",
    todofuken_id: Todofuken.Kanagawa,
  },
  {
    id: ShikugunGroup.Kawasaki,
    display_name: "川崎市",
    short_display_name: null,
    slug: "kawasaki",
    todofuken_id: Todofuken.Kanagawa,
  },
  {
    id: ShikugunGroup.Sagamihara,
    display_name: "相模原市",
    short_display_name: null,
    slug: "sagamihara",
    todofuken_id: Todofuken.Kanagawa,
  },
];
