import _ from "lodash";
import { AbstractRegionCondition } from "./Abstract/AbstractRegionCondition";
import { getMunicipality } from "~/types/Models/Municipality";
import { getMunicipalityGroup } from "~/types/Models/MunicipalityGroup";
import { getPrefecture } from "~/types/Models/Prefecture";

export class MunicipalityCondition extends AbstractRegionCondition {
  private readonly _prefectureSlugs: string[];
  private readonly _municipalityGroupIds: number[];
  private readonly _jisX_0401_0402Codes: string[];

  constructor(
    _prefectureSlugs: string[],
    _municipalityGroupIds: number[],
    _jisX_0401_0402Codes: string[],
  ) {
    super();
    this._prefectureSlugs = _.uniq(_prefectureSlugs);
    this._municipalityGroupIds = _.uniq(_municipalityGroupIds);
    this._jisX_0401_0402Codes = _.uniq(_jisX_0401_0402Codes);
  }

  get legacy(): boolean {
    return false;
  }

  protected setSourceData(): void {
    const prefectures = this._prefectureSlugs.map((slug) => getPrefecture({ slug }));
    const municipalityGroups = this._municipalityGroupIds.map((id) => getMunicipalityGroup({ id }));
    const municipalities = this._jisX_0401_0402Codes.map((code) => getMunicipality(code));

    this._sourceData = {
      prefectures,
      municipalityGroups,
      municipalities,
    };
  }
}
