/**
 * 予約タイプ
 *
 * 問い合わせ種別と同じ値を設定している。
 */
export const ReservationType = {
  Preview: "preview_reservation", // 見学予約
  Visit: "visit_reservation", // 来店予約
  Online: "online_reservation", // オンライン予約
  VisitOrOnline: "visit_or_online_reservation", // 来店orオンライン予約
} as const;
export type ReservationType = (typeof ReservationType)[keyof typeof ReservationType];
