enum IncompatibleConditionType {
  Route = "駅・沿線",
  Region = "地域",
}

export class IncompatibleError extends TypeError {
  constructor(type: IncompatibleConditionType) {
    super(`${type}のクエリは、新コードと旧コードを同時に指定することはできません。`);
  }

  static route(): IncompatibleError {
    return new this(IncompatibleConditionType.Route);
  }

  static region(): IncompatibleError {
    return new this(IncompatibleConditionType.Region);
  }
}
