/**
 * 問い合わせ種別
 */
export const InquiryType = {
  PreviewReservation: "preview_reservation",
  VisitReservation: "visit_reservation",
  OnlineReservation: "online_reservation",
  VisitOrOnlineReservation: "visit_or_online_reservation",
  RentalApplication: "rental_application",
  OtherInquiry: "other_inquiry",
  NoListedRequest: "no_listed_request",
  ConsultationRequest: "consultation_request",
  LineInquiry: "line_inquiry",
} as const;
export type InquiryType = (typeof InquiryType)[keyof typeof InquiryType];
