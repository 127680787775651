import { airdoor, MunicipalityData } from "@airdoor/master-data";
import Shikugun from "../region/ShikugunType";
import { generateMunicipalityGroup } from "./MunicipalityGroup";
import { generatePrefecture } from "./Prefecture";

export type Municipality = {
  jisX_0401_0402: string;
  displayName: string;
  url: string;
  prefectureSlug: string;
  municipalityGroupSlug: string;
};

export const generateMunicipality = (
  shikugun: Shikugun,
): {
  displayName: string;
  url: string;
  group: ReturnType<typeof generateMunicipalityGroup> | undefined;
  prefecture: ReturnType<typeof generatePrefecture>;
} => {
  return {
    displayName: shikugun.short_display_name || shikugun.display_name,
    url: "/list?si=d-" + shikugun.id,
    group: shikugun.shikugun_group && generateMunicipalityGroup(shikugun.shikugun_group),
    prefecture: generatePrefecture(shikugun.todofuken),
  };
};

export const getMunicipality = (jisX_0401_0402: string): Municipality => {
  const m = airdoor.municipalities.get(jisX_0401_0402);

  return makeMunicipality(m);
};

export const makeMunicipality = (m: MunicipalityData): Municipality => {
  const legacyCode = withCheckDigit(m.jisX_0401_0402);

  return {
    jisX_0401_0402: m.jisX_0401_0402,
    displayName: m.name,
    url: "/list?si=d-" + legacyCode,
    prefectureSlug: m.prefectureSlug,
    municipalityGroupSlug: m.municipalityGroupSlug,
  };
};

/**
 * 5桁のコードからチェックディジットを計算し、6桁のコードを返す関数
 * @param {string | number} code - 5桁の全国地方公共団体コード
 * @returns {string} 6桁のコード（チェックディジット付き）
 */
function withCheckDigit(code: string | number): string {
  const codeStr = String(code);
  if (!/^\d{5}$/.test(codeStr)) {
    throw new Error("コードは5桁の数値である必要があります。");
  }

  const digits = codeStr.split("").map(Number);
  const [a, b, c, d, e] = digits;

  const weightedSum = a * 6 + b * 5 + c * 4 + d * 3 + e * 2;
  const remainder = weightedSum % 11;
  const checkDigit = (11 - remainder) % 10;

  return codeStr + checkDigit;
}
