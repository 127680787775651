import { airdoor, MunicipalityGroupData } from "@airdoor/master-data";
import ShikugunGroup from "../region/ShikugunGroupType";
import { generatePrefecture } from "./Prefecture";

type MunicipalityGroupBase = {
  slug: string;
  jisX_0401: string;
  prefectureSlug: string;
  displayName: string;
  crumbName: string;
  url: string | undefined;

  jisX_0401_0402Codes: string[];
};

export type QueryableMunicipalityGroup = MunicipalityGroupBase & {
  id: number;
};
export type NotQueryableMunicipalityGroup = MunicipalityGroupBase & {
  id: undefined;
};

export type MunicipalityGroup = QueryableMunicipalityGroup | NotQueryableMunicipalityGroup;

export const generateMunicipalityGroup = (
  shikugunGroup: ShikugunGroup,
): { displayName: string; url: string; prefecture: ReturnType<typeof generatePrefecture> } => {
  return {
    displayName: shikugunGroup.short_display_name ?? shikugunGroup.display_name,
    url: "/list?sg=d-" + shikugunGroup.id,
    prefecture: generatePrefecture(shikugunGroup.todofuken),
  };
};

export function getMunicipalityGroup(where: { id: number }): QueryableMunicipalityGroup;
export function getMunicipalityGroup(where: { slug: string }): MunicipalityGroup;
export function getMunicipalityGroup(where: { slug: string } | { id: number }): MunicipalityGroup {
  const municipalityGroup =
    "slug" in where
      ? airdoor.municipalityGroups.get(where.slug)
      : airdoor.municipalityGroups.getById(where.id);
  return makeMunicipalityGroup(municipalityGroup);
}
export function getFulfilledGroups(jisCodes: string[]): MunicipalityGroup[] {
  if (jisCodes.length < 1) return [];

  const groups = airdoor.municipalityGroups.all();
  return groups
    .filter((group) => group.municipalityJisCodes.every((jisCode) => jisCodes.includes(jisCode)))
    .map((g) => makeMunicipalityGroup(g));
}

export const makeMunicipalityGroup = (data: MunicipalityGroupData): MunicipalityGroup => {
  const legacyCode: string | undefined = convertLegacyCode(data.slug);

  return {
    id: data.id,
    slug: data.slug,
    jisX_0401: data.prefecture.jisX_0401,
    prefectureSlug: data.prefectureSlug,
    displayName: data.name,
    crumbName: data.shortName || data.name,
    url: legacyCode ? "/list?sg=d-" + legacyCode : undefined,
    jisX_0401_0402Codes: data.municipalityJisCodes,
  };
};

const convertLegacyCode = (slug: string): string | undefined => {
  return {
    "23ku": "131000",
    yokohama: "141003",
    kawasaki: "141305",
    sagamihara: "141500",
  }[slug];
};
