import { airdoor, PrefectureData } from "@airdoor/master-data";
import Todofuken from "../region/TodofukenType";

export type Prefecture = {
  slug: string;
  jisX_0401: string;
  displayName: string;
  url: string | undefined;
};

export const generatePrefecture = (
  todofuken: Todofuken,
): {
  displayName: string;
  url: string;
} => {
  return {
    displayName: todofuken.display_name,
    url: "/list?to=d-" + todofuken.id,
  };
};

export function getPrefecture(where: { jisX_0401: string }): Prefecture;
export function getPrefecture(where: { slug: string }): Prefecture;
export function getPrefecture(where: { jisX_0401: string } | { slug: string }): Prefecture {
  const pref =
    "slug" in where
      ? airdoor.prefectures.get(where.slug)
      : airdoor.prefectures.getByJisCode(where.jisX_0401);

  return makePrefecture(pref);
}
export function getFulfilledPrefectures(groupSlugs: string[]): Prefecture[] {
  if (groupSlugs.length < 1) return [];

  const prefectures = airdoor.prefectures.all();
  return prefectures
    .filter((pref) => pref.municipalityGroupSlugs.every((slug) => groupSlugs.includes(slug)))
    .map((p) => makePrefecture(p));
}

export const makePrefecture = (data: PrefectureData): Prefecture => {
  return {
    jisX_0401: data.jisX_0401,
    slug: data.slug,
    displayName: data.name,
    url: `/list?to=d-${data.jisX_0401}`,
  };
};
