export const ExclusiveAreaConditionHelper = {
  lowerExclusiveAreaOptions: [15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],
  upperExclusiveAreaOptions: [15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100],

  getText: (lowerExclusiveArea: string, upperExclusiveArea: string): string => {
    if (!lowerExclusiveArea && !upperExclusiveArea) return "";
    return (
      (lowerExclusiveArea && lowerExclusiveArea + "㎡") +
      "〜" +
      (upperExclusiveArea && upperExclusiveArea + "㎡")
    );
  },
};

type ExclusiveAreaConditionProps = {
  lowerExclusiveArea: string;
  setLowerExclusiveArea: (lowerExclusiveArea: string) => void;
  upperExclusiveArea: string;
  setUpperExclusiveArea: (upperExclusiveArea: string) => void;
};

const ExclusiveAreaCondition = ({
  lowerExclusiveArea,
  setLowerExclusiveArea,
  upperExclusiveArea,
  setUpperExclusiveArea,
}: ExclusiveAreaConditionProps): JSX.Element => {
  return (
    <>
      <div className={lowerExclusiveArea ? "sel is-active" : "sel"}>
        <select
          name="lowerExclusiveArea"
          id="lowerExclusiveArea"
          value={lowerExclusiveArea}
          onChange={(e) => setLowerExclusiveArea(e.target.value)}
        >
          <option value="">下限なし</option>
          {ExclusiveAreaConditionHelper.lowerExclusiveAreaOptions.map((option) => (
            <option value={option} key={`lowerExclusiveArea_${option}`}>
              {option}㎡
            </option>
          ))}
        </select>
      </div>
      <p>〜</p>
      <div className={upperExclusiveArea ? "sel is-active" : "sel"}>
        <select
          name="upperExclusiveArea"
          id="upperExclusiveArea"
          value={upperExclusiveArea}
          onChange={(e) => setUpperExclusiveArea(e.target.value)}
        >
          <option value="">上限なし</option>
          {ExclusiveAreaConditionHelper.upperExclusiveAreaOptions.map((option) => (
            <option value={option} key={`upperExclusiveArea_${option}`}>
              {option}㎡
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

export default ExclusiveAreaCondition;
