import { getMunicipality } from "../Models/Municipality";
import { getMunicipalityGroup } from "../Models/MunicipalityGroup";
import { getPrefecture } from "../Models/Prefecture";
import Todofuken from "../region/TodofukenType";
import ShikugunConditionType from "./ShikugunConditionType";
import ShikugunGroupConditionType from "./ShikugunGroupConditionType";
import TodofukenConditionType from "./TodofukenConditionType";
import { AbstractRegionCondition } from "@/pages/list/Logic/Condition/Abstract/AbstractRegionCondition";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export default class RegionConditionType extends AbstractRegionCondition {
  private readonly _todofuken_conditions: TodofukenConditionType[];

  constructor(
    todofukenStatus: { [id: string]: boolean } | null = null,
    shikugunStatus: { [id: string]: boolean } | null = null,
    shikugunGroupStatus: { [id: string]: boolean } | null = null,
  ) {
    super();
    this._todofuken_conditions = Todofuken.activeAll().map(
      (todofuken) => new TodofukenConditionType(todofuken),
    );

    if (!!todofukenStatus) {
      for (const [id, b] of Object.entries(todofukenStatus)) {
        this.findTodofuken(id)?.setChecked(b);
      }
    }
    if (!!shikugunStatus) {
      for (const [id, b] of Object.entries(shikugunStatus)) {
        this.findShikugun(id)?.setChecked(b);
      }
    }
    if (!!shikugunGroupStatus) {
      for (const [id, b] of Object.entries(shikugunGroupStatus)) {
        this.findShikugunGroup(id)?.setChecked(b);
      }
    }
  }

  get todofuken_conditions(): TodofukenConditionType[] {
    return this._todofuken_conditions;
  }

  get shikugun_group_conditions(): ShikugunGroupConditionType[] {
    return this._todofuken_conditions.flatMap((t) => t.shikugun_group_conditions);
  }

  get shikugun_conditions(): ShikugunConditionType[] {
    return this._todofuken_conditions.flatMap((t) => t.shikugun_conditions);
  }

  /****************
   * 検索系メソッド *
   ****************/
  findTodofuken = (id: string): TodofukenConditionType | undefined => {
    return this.todofuken_conditions.find((todofuken) => todofuken.id === id);
  };

  findShikugunGroup = (id: string): ShikugunGroupConditionType | undefined => {
    return this.shikugun_group_conditions.find((shikugun) => shikugun.id === id);
  };

  findShikugun = (id: string): ShikugunConditionType | undefined => {
    return this.shikugun_conditions.find((shikugun) => shikugun.id === id);
  };

  /************************************
   * 検索系メソッド(なければエラーを返す版) *
   ************************************/
  findTodofukenStrictly = (id: string): TodofukenConditionType => {
    const target = this.findTodofuken(id);
    if (!target) {
      throw new TypeError(`todofukenId ${id} is invalid.`);
    }
    return target;
  };

  findShikugunGroupStrictly = (id: string): ShikugunGroupConditionType => {
    const target = this.findShikugunGroup(id);
    if (!target) {
      throw new TypeError(`shikugunGroupId ${id} is invalid.`);
    }
    return target;
  };

  findShikugunStrictly = (id: string): ShikugunConditionType => {
    const target = this.findShikugun(id);
    if (!target) {
      throw new TypeError(`shikugunId ${id} is invalid.`);
    }
    return target;
  };

  /******************************
   * チェック状態の確認系メソッド *
   ******************************/
  // チェックされている都道府県を取得
  getCheckedTodofukens = (
    { excludesEveryShikugunSelected } = {
      excludesEveryShikugunSelected: false,
    },
  ): TodofukenConditionType[] => {
    return this.todofuken_conditions.filter(
      (t) => (!excludesEveryShikugunSelected || !this.everyShikugunsIsChecked()) && t.is_checked,
    );
  };

  // チェックされている市区郡カテゴリを取得
  getCheckedShikugunGroups = (
    { excludesTodofukenSelected } = {
      excludesTodofukenSelected: false,
    },
  ): ShikugunGroupConditionType[] => {
    return this.shikugun_group_conditions.filter(
      (sg) =>
        (!excludesTodofukenSelected || !sg.todofuken_condition.is_checked) &&
        sg.id &&
        sg.is_checked,
    );
  };

  // チェックされた市区郡の取得
  // excludesTodofukenSelectedをtrueにすると、都道府県が選択されている市区郡を除外する
  // excludesShikugunGroupSelectedをtrueにすると、市区郡グループが選択されている市区郡を除外する
  getCheckedShikuguns(
    { excludesTodofukenSelected, excludesShikugunGroupSelected } = {
      excludesTodofukenSelected: false,
      excludesShikugunGroupSelected: false,
    },
  ): ShikugunConditionType[] {
    return this.shikugun_conditions.filter(
      (s) =>
        (!excludesTodofukenSelected || !s.todofuken_condition?.is_checked) &&
        (!excludesShikugunGroupSelected ||
          s.shikugun_group_id === null ||
          !s.shikugun_group_condition?.is_checked) &&
        s.is_checked,
    );
  }

  // チェックされている都道府県があるか否か
  hasCheckedTodofuken = (): boolean => {
    return this.todofuken_conditions.some((t) => t.is_checked);
  };

  // 1市区郡でもチェックされているか否か
  hasCheckedShikugun(): boolean {
    return this.shikugun_conditions.some((s) => s.is_checked);
  }

  // すべての市区郡がチェックされているか
  everyShikugunsIsChecked(): boolean {
    return this.shikugun_conditions.every((s) => s.is_checked);
  }

  /******************************
   * チェック状態の変更系メソッド *
   ******************************/
  // 配下のエリアを含めて、チェック状態を変更する（外部から呼ぶメソッド）
  setChecked = (b: boolean): void => {
    this.todofuken_conditions.forEach((t) => t.setChecked(b));
  };

  // 全てを更新した上で、新しいインスタンスを返す
  static setAll = (regionCondition: RegionConditionType, b: boolean): RegionConditionType => {
    const clone = Object.assign(new RegionConditionType(), regionCondition);
    clone.setChecked(b);
    return clone;
  };

  // 指定された都道府県を更新した上で、新しいインスタンスを返す
  static setTodofuken = (
    regionCondition: RegionConditionType,
    todofukenId: string,
    b: boolean,
  ): RegionConditionType => {
    const clone = Object.assign(new RegionConditionType(), regionCondition);
    clone.findTodofuken(todofukenId)?.setChecked(b);
    return clone;
  };

  // 指定された市区郡カテゴリを更新した上で、新しいインスタンスを返す
  static setShikugunGroup = (
    regionCondition: RegionConditionType,
    shikugunGroupId: string,
    b: boolean,
  ): RegionConditionType => {
    const clone = Object.assign(new RegionConditionType(), regionCondition);
    clone.findShikugunGroup(shikugunGroupId)?.setChecked(b);
    return clone;
  };

  // 指定された市区郡を更新した上で、新しいインスタンスを返す
  static setShikugun = (
    regionCondition: RegionConditionType,
    shikugunId: string,
    b: boolean,
  ): RegionConditionType => {
    const clone = Object.assign(new RegionConditionType(), regionCondition);
    clone.findShikugun(shikugunId)?.setChecked(b);
    return clone;
  };

  static setShikugunsExceptShikugunGroup = (
    regionCondition: RegionConditionType,
    todofukenId: string,
    b: boolean,
  ): RegionConditionType => {
    const clone = Object.assign(new RegionConditionType(), regionCondition);
    clone.shikugun_conditions.map(
      (shikugun) =>
        shikugun.data.todofuken_id === todofukenId &&
        shikugun.data.shikugun_group_id === null &&
        shikugun?.setChecked(b),
    );
    return clone;
  };
  /*****************
   * 表示系メソッド *
   *****************/
  // チェックされた内容を全てまとめたテキストを返す
  getText(): string {
    return this.todofuken_conditions
      .map((t) => t.getText())
      .filter((v) => !!v)
      .join(", ");
  }

  // 物件一覧のタイトルに用いる、短いテキストを返す
  // チェックされている都道府県/市区郡のうち最初のものを表示
  getTitleText = (): string => {
    return (
      this.getCheckedTodofukens()?.[0]?.data.display_name ||
      this.getCheckedShikugunGroups()?.[0]?.data.display_name ||
      this.getCheckedShikuguns()?.[0]?.data.display_name ||
      ""
    );
  };

  // 都道府県パラメータの生成
  getTodofukenParameter = (excludesEveryShikugunSelected = false): { [key: string]: boolean } => {
    return this.getCheckedTodofukens({ excludesEveryShikugunSelected }).reduce<
      Record<string, boolean>
    >((obj, todofuken) => {
      obj[todofuken.id] = todofuken.is_checked;
      return obj;
    }, {});
  };

  // 都道府県グループのパラメータの生成
  getShikugunGroupParameter = (excludesTodofukenSelected = false): { [key: string]: boolean } => {
    return this.getCheckedShikugunGroups({ excludesTodofukenSelected }).reduce<
      Record<string, boolean>
    >((obj, shikugunGroup) => {
      if (shikugunGroup.id !== null) {
        obj[shikugunGroup.id] = shikugunGroup.is_checked;
      }
      return obj;
    }, {});
  };

  // 市区郡パラメータの生成
  // 引数をtrueにすると、都道府県が選択されている市区郡を除外する
  getShikugunParameter = (
    excludesTodofukenSelected = false,
    excludesShikugunGroupSelected = false,
  ): { [key: string]: boolean } => {
    return this.getCheckedShikuguns({
      excludesTodofukenSelected,
      excludesShikugunGroupSelected,
    }).reduce<Record<string, boolean>>((obj, shikugun) => {
      obj[shikugun.id] = shikugun.is_checked;
      return obj;
    }, {});
  };

  // クエリの生成
  getQuery = (): { [key: string]: string } => {
    const buildQuery = (key: string, values: string[]) => {
      return {
        [key]: ["d", ...values].join("-"),
      };
    };

    const getTodofukenQuery = (): { [key: string]: string } => {
      const ids = this.getCheckedTodofukens().map((todofuken) => todofuken.id);
      return ids.length > 0 ? buildQuery(QUERY_KEY.TODOFUKEN, ids) : {};
    };

    const getShikugunGroupQuery = (): { [key: string]: string } => {
      const ids = this.getCheckedShikugunGroups({ excludesTodofukenSelected: true })
        .map((shikugunGroup) => shikugunGroup.id)
        .filter((id): id is string => id !== null);
      return ids.length > 0 ? buildQuery(QUERY_KEY.SHIKUGUN_GROUP, ids) : {};
    };

    const getShikugunQuery = (): { [key: string]: string } => {
      const ids = this.getCheckedShikuguns({
        excludesTodofukenSelected: true,
        excludesShikugunGroupSelected: true,
      }).map((shikugun) => shikugun.id);
      return ids.length > 0 ? buildQuery(QUERY_KEY.SHIKUGUN, ids) : {};
    };

    return {
      ...getTodofukenQuery(),
      ...getShikugunGroupQuery(),
      ...getShikugunQuery(),
    };
  };

  /***************************************
   * RouteConditionInterface 用のメソッド **
   ***************************************/
  get legacy(): boolean {
    return true;
  }

  protected setSourceData(): void {
    const prefectures = this.getCheckedTodofukens().map((t) => getPrefecture({ jisX_0401: t.id }));
    const municipalityGroups = this.getCheckedShikugunGroupsWithOther().map((sg) =>
      getMunicipalityGroup({ slug: sg.data.slug }),
    );
    const municipalities = this.getCheckedShikuguns().map((m) =>
      getMunicipality(m.data.id.slice(0, 5)),
    );

    this._sourceData = {
      prefectures,
      municipalityGroups,
      municipalities,
    };
  }

  // その他も取得する
  private getCheckedShikugunGroupsWithOther = (): ShikugunGroupConditionType[] => {
    return this.shikugun_group_conditions.filter((sg) => sg.is_checked);
  };
}
