// API化した場合における、都道府県テーブルに該当

export type TodofukenSchema = {
  id: string;
  display_name: string;
  slug: string;
  is_active: boolean; // 現在はtrue固定だが、マスタ運用をし始めるとfalseが必要になる見込み
};

export enum Todofuken {
  Tokyo = "13",
  Kanagawa = "14",
}

export const TODOFUKEN_TABLE: TodofukenSchema[] = [
  {
    id: Todofuken.Tokyo,
    display_name: "東京都",
    slug: "tokyo",
    is_active: true,
  },
  {
    id: Todofuken.Kanagawa,
    display_name: "神奈川県",
    slug: "kanagawa",
    is_active: true,
  },
];
