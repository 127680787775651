import Image from "next/image";
import Link from "next/link";
import { FC } from "react";
import styles from "@/layouts/AppStoreBanner/AppStoreBanner.module.scss";
import appIcon from "public/static/images/icon/airdoor-app-icon.png";
import Cross from "public/static/images/icon/cross.svg";
import Download from "public/static/svgs/header/download_white.svg";
import Stars from "public/static/svgs/header/rating-stars.svg";
import { APP_OPEN_LINK } from "~/const/app";

type Props = {
  updateIsHideAppBanner: () => void;
};

const AppStoreBanner: FC<Props> = ({ updateIsHideAppBanner }) => (
  <div className={styles.bannerContainer}>
    <div className={styles.leftContent}>
      <div className={styles.closeButton} onClick={updateIsHideAppBanner}>
        <Cross />
      </div>
      <Image src={appIcon} alt="エアドアアプリアイコン" width={48} height={48} />
      <div>
        <p className={styles.text}>{"空室情報がすぐ届く！\nエアドア公式アプリ"}</p>
        <div className={styles.rating}>
          <Stars />
          <span className={styles.grayText}>無料</span>
        </div>
      </div>
    </div>
    <Link className={styles.installLink} href={APP_OPEN_LINK} target="_blank">
      <Download />
      <span className={styles.linkText}>インストール</span>
    </Link>
  </div>
);

export default AppStoreBanner;
