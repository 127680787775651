import styles from "./RoomCarousel.module.scss";
import RoomCard from "@/common/partials/RoomCard/RoomCard";
import { ConversionPoint } from "~/features/inquiry/enums";

type BaseRoomCarouselBuildingProp = Parameters<typeof RoomCard>["0"]["building"];
type BaseRoomCarouselRoomProp = Parameters<typeof RoomCard>["0"]["room"];

const BaseRoomCarousel: React.FunctionComponent<{
  items: {
    building: BaseRoomCarouselBuildingProp & { recommend_room: BaseRoomCarouselRoomProp };
  }[];
  title?: JSX.Element;
  cvPoint?: ConversionPoint | undefined;
}> = ({ items, title, cvPoint }) => {
  return (
    <>
      <div>{title || ""}</div>
      <div className={styles.roomCarouselContent}>
        {items.map((item, i) => (
          <div
            key={`${i}_${item.building.id}_${item.building.recommend_room.id}`}
            className={styles.roomCarouselContentItem}
          >
            <RoomCard
              building={item.building}
              room={item.building.recommend_room}
              cvPoint={cvPoint}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default BaseRoomCarousel;
