/**
 * 検索条件を司るタイプクラス
 *
 * CategoryCondition: 検索条件
 * ・一覧画面でのカテゴリ条件を司る
 * ・複数のConditionを持つ場合がある
 */

import { isExistParkingValues } from "./values/parking";
import { ConditionColumn, SqlOperator, WhereConditions } from "~/generated/graphql";
import {
  DirectionTypeInputEnum,
  GasTypeInputEnum,
  ParkingStatusTypeInputEnum,
  RentalRestrictionGenderInputEnum,
  RentalRestrictionMusicalInstrumentInputEnum,
  RentalRestrictionOfficeUseInputEnum,
  RentalRestrictionPetInputEnum,
  RentalRestrictionRoomShareInputEnum,
  SearchBuildingsWhereInput,
  SearchRoomsWhereInput,
} from "~/generated/v2/graphql";

export const CategoryConditionEntity = {
  Room: "room",
  Building: "building",
} as const;
export type CategoryConditionEntity =
  (typeof CategoryConditionEntity)[keyof typeof CategoryConditionEntity];

type BaseCategoryCondition = {
  id: number; // 検索ID
  order: number; // 表示順
  kodawari_flg?: boolean; // こだわり条件で表示するか否か
  display_name: string; // 検索条件の表示名
  seo_title: string | undefined; // metaタグや検索一覧のタイトルに使用する名称
  is_display: boolean; // 表示するか否か
  category_type?: string; // カテゴリ条件のタイプ
  conditions: WhereConditions; // where条件
};

type RoomCategoryCondition = BaseCategoryCondition & {
  entity: "room";
  v2_condition: SearchRoomsWhereInput;
};

type BuildingCategoryCondition = BaseCategoryCondition & {
  entity: "building";
  v2_condition: SearchBuildingsWhereInput;
};

type CategoryConditionProp = RoomCategoryCondition | BuildingCategoryCondition;

/**
 * こだわり検索条件
 */
export class CategoryCondition {
  id: number;
  order: number;
  kodawari_flg?: boolean;
  display_name: string;
  category_type?: string;
  is_display: boolean;
  entity: CategoryConditionEntity;
  conditions: WhereConditions;
  v2_condition: SearchRoomsWhereInput | SearchBuildingsWhereInput;
  private _seo_title: string;

  constructor(categoryCondition: CategoryConditionProp) {
    this.id = categoryCondition.id;
    this.order = categoryCondition.order;
    this.kodawari_flg = categoryCondition.kodawari_flg;
    this.display_name = categoryCondition.display_name;
    this.category_type = categoryCondition.category_type;
    this.is_display = categoryCondition.is_display;
    this.entity = categoryCondition.entity;
    this.conditions = categoryCondition.conditions;
    this.v2_condition = categoryCondition.v2_condition;
    this._seo_title = categoryCondition.seo_title;
  }

  get seo_title(): string {
    return this._seo_title ?? this.display_name;
  }
}

const categoryConditionProps: CategoryConditionProp[] = [
  /*------ お得条件 ------*/
  {
    id: 25,
    order: 1,
    display_name: "仲介手数料無料",
    seo_title: "仲介手数料無料(0円・ゼロ)",
    category_type: "お得条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.BrokerFee,
      operator: SqlOperator.Eq,
      value: 0,
    },
    v2_condition: {
      broker_fee: { equals: 0 },
    },
  },
  {
    id: 0,
    order: 2,
    display_name: "敷金・保証金なし",
    seo_title: "敷金なし(0円・ゼロ)",
    category_type: "お得条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      AND: [
        {
          column: ConditionColumn.SecurityDeposit,
          operator: SqlOperator.Eq,
          value: 0,
        },
        {
          column: ConditionColumn.Deposit,
          operator: SqlOperator.Eq,
          value: 0,
        },
      ],
    },
    v2_condition: {
      security_deposit: { equals: 0 },
      deposit: { equals: 0 },
    },
  },
  {
    id: 1,
    order: 3,
    display_name: "礼金なし",
    seo_title: "礼金なし(0円・ゼロ)",
    category_type: "お得条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.KeyMoney,
      operator: SqlOperator.Eq,
      value: 0,
    },
    v2_condition: {
      key_money: { equals: 0 },
    },
  },
  {
    id: 26,
    order: 3,
    display_name: "初期費用分割可能",
    seo_title: undefined,
    category_type: "お得条件",
    is_display: false,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.AdvertisementRate,
      operator: SqlOperator.Gte,
      value: 99999999, //現在利用停止。googleにindexされてしまっているため、検索ヒット0件で表示させるためありえない数値を指定。復活した場合は100に戻す。
    },
    v2_condition: {
      advertisement_rate: { gte: 99999999 },
    },
  },
  {
    id: 28,
    order: 5,
    display_name: "フリーレント付き",
    seo_title: "フリーレント(一定家賃無料)",
    category_type: "お得条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsFreerent,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_freerent: { equals: true },
    },
  },

  {
    id: 23,
    order: 6,
    display_name: "インターネット無料",
    seo_title: "インターネット無料(Wi-Fi付)",
    category_type: "お得条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.InternetCost,
      operator: SqlOperator.Eq,
      value: 2,
    },
    v2_condition: {
      internet_cost: { equals: 2 },
    },
  },

  /*------ フロア ------*/
  {
    id: 29,
    order: 1,
    display_name: "南向き",
    seo_title: undefined,
    category_type: "フロア",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.DirectionId,
      operator: SqlOperator.Eq,
      value: 5,
    },
    v2_condition: {
      direction_id: { equals: DirectionTypeInputEnum.South },
    },
  },
  {
    id: 8,
    order: 2,
    kodawari_flg: true,
    display_name: "2階以上",
    seo_title: undefined,
    category_type: "フロア",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.LocationFloor,
      operator: SqlOperator.Gte,
      value: 2,
    },
    v2_condition: {
      location_floor: { gte: "2" },
    },
  },
  {
    id: 30,
    order: 3,
    display_name: "角部屋",
    seo_title: undefined,
    category_type: "フロア",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsCornerRoom,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_corner_room: { equals: true },
    },
  },
  {
    id: 7,
    order: 4,
    display_name: "最上階",
    seo_title: undefined,
    is_display: true,
    category_type: "フロア",
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsTopFloor,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_top_floor: { equals: true },
    },
  },

  /*------ キッチン ------*/
  {
    id: 31,
    order: 1,
    display_name: "ガスコンロ対応",
    seo_title: undefined,
    category_type: "キッチン",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.CookingStoveTypeId,
      operator: SqlOperator.In,
      value: [1, 4], // "ガス", "ガスコンロ設置"
    },
    v2_condition: {
      cooking_stove_type_id: { equals: 1 },
    },
  },
  {
    id: 15,
    order: 2,
    display_name: "コンロ２口以上",
    seo_title: undefined,
    category_type: "キッチン",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.NumberOfCookingStove,
      operator: SqlOperator.Gt,
      value: 1,
    },
    v2_condition: {
      number_of_cooking_stove: { gte: 2 },
    },
  },
  {
    id: 32,
    order: 3,
    display_name: "IH",
    seo_title: undefined,
    category_type: "キッチン",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.CookingStoveTypeId,
      operator: SqlOperator.Eq,
      value: 3,
    },
    v2_condition: {
      cooking_stove_type_id: { equals: 3 },
    },
  },
  {
    id: 42,
    order: 4,
    display_name: "システムキッチン",
    seo_title: undefined,
    category_type: "キッチン",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsSystemKitchen,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_system_kitchen: { equals: true },
    },
  },

  /*------ バストイレ ------*/
  {
    id: 10,
    order: 1,
    kodawari_flg: true,
    display_name: "バス・トイレ別",
    seo_title: undefined,
    category_type: "バストイレ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsBathtoiletSeparated,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_bathtoilet_separated: { equals: true },
    },
  },
  {
    id: 43,
    order: 2,
    display_name: "室内洗濯機置場",
    seo_title: undefined,
    category_type: "バストイレ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.WashingMachineStorage,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      washing_machine_storage: { equals: 1 },
    },
  },
  {
    id: 11,
    order: 3,
    display_name: "独立洗面台",
    seo_title: undefined,
    category_type: "バストイレ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsIndependentWashroom,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_independent_washroom: { equals: true },
    },
  },
  {
    id: 12,
    order: 4,
    display_name: "浴室乾燥機",
    seo_title: undefined,
    category_type: "バストイレ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsBathroomDryer,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_bathroom_dryer: { equals: true },
    },
  },
  {
    id: 14,
    order: 5,
    display_name: "追い焚き",
    seo_title: undefined,
    category_type: "バストイレ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsReheating,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_reheating: { equals: true },
    },
  },
  {
    id: 13,
    order: 6,
    display_name: "洗浄便座",
    seo_title: undefined,
    category_type: "バストイレ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsWashToiletSeat,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_wash_toilet_seat: { equals: true },
    },
  },

  /*------ TV・通信 ------*/
  {
    id: 46,
    order: 2,
    display_name: "インターネット接続可",
    seo_title: undefined,
    category_type: "TV・通信",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsInternetAvailable,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_internet_available: { equals: true },
    },
  },
  {
    id: 51,
    order: 3,
    display_name: "BSアンテナ",
    seo_title: undefined,
    category_type: "TV・通信",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.SatelliteBroadcasting,
      operator: SqlOperator.In,
      value: [2, 3],
    },
    v2_condition: {
      satellite_broadcasting: { in: [2, 3] },
    },
  },
  {
    id: 52,
    order: 4,
    display_name: "CSアンテナ",
    seo_title: undefined,
    category_type: "TV・通信",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.SatelliteBroadcasting,
      operator: SqlOperator.In,
      value: [1, 3],
    },
    v2_condition: {
      satellite_broadcasting: { in: [1, 3] },
    },
  },
  {
    id: 53,
    order: 5,
    display_name: "ケーブルテレビ",
    seo_title: undefined,
    category_type: "TV・通信",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsCableTv,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_cable_tv: { equals: true },
    },
  },

  /*------ 室内 ------*/
  {
    id: 44,
    order: 1,
    display_name: "エアコン付き",
    seo_title: undefined,
    category_type: "室内",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.AirConditioningStatus,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      air_conditioning_status: { equals: 1 },
    },
  },
  {
    id: 33,
    order: 2,
    display_name: "ウォークインクローゼット",
    seo_title: undefined,
    category_type: "室内",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsWalkInCloset,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_walk_in_closet: { equals: true },
    },
  },
  {
    id: 6,
    order: 3,
    display_name: "家具家電付き",
    seo_title: undefined,
    category_type: "室内",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.FurnitureApplianceStatus,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      furniture_appliance_status: { equals: 1 },
    },
  },
  {
    id: 45,
    order: 4,
    display_name: "フローリング",
    seo_title: undefined,
    category_type: "室内",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsFlooring,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_flooring: { equals: true },
    },
  },
  {
    id: 34,
    order: 5,
    display_name: "床暖房",
    seo_title: undefined,
    category_type: "室内",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsFloorHeating,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_floor_heating: { equals: true },
    },
  },

  /*------ セキュリティ ------*/
  {
    id: 20,
    order: 1,
    kodawari_flg: true,
    display_name: "オートロック",
    seo_title: undefined,
    category_type: "セキュリティ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsAutolock,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_autolock: { equals: true },
    },
  },
  {
    id: 54,
    order: 2,
    display_name: "TVモニタ付きドアホン",
    seo_title: undefined,
    category_type: "セキュリティ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IntercomSystem,
      operator: SqlOperator.In,
      value: [1, 2],
    },
    v2_condition: {
      intercom_system: { in: [1, 2] },
    },
  },
  {
    id: 55,
    order: 3,
    display_name: "防犯カメラ",
    seo_title: undefined,
    category_type: "セキュリティ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsSecurityCamera,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_security_camera: { equals: true },
    },
  },
  {
    id: 56,
    order: 4,
    display_name: "管理人有り",
    seo_title: undefined,
    category_type: "セキュリティ",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsCaretaker,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_caretaker: { equals: true },
    },
  },
  // 物件数が少ないためコメントアウト
  // {
  //   id: 16,
  //   order: 5,
  //   display_name: "24時間管理",
  //   category_type: "セキュリティ",
  //   is_display: true,
  //   conditions: [
  //     {
  //       entity: "building",
  //       variable: ConditionColumn.Is_24HourMonitoring,
  //       operator: SqlOperator.Eq,
  //       value: 1,
  //     },
  //   ],
  // },

  /*------ 建物設備 ------*/
  {
    id: 35,
    order: 1,
    display_name: "エレベーター",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsExistElevator,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_exist_elevator: { equals: true },
    },
  },
  {
    id: 19,
    order: 2,
    display_name: "宅配ボックス",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsExistParcelBox,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_exist_parcel_box: { equals: true },
    },
  },
  // 一物件も存在しないためコメントアウト
  // {
  //   id: 36,
  //   order: 3,
  //   display_name: "敷地内ゴミ置場",
  //   category_type: "建物設備",
  //   is_display: true,
  //   conditions: [
  //     {
  //       entity: "building",
  //       variable: ConditionColumn.GarbageStorage,
  //       operator: SqlOperator.Eq,
  //       value: 1,
  //     },
  //   ],
  // },
  {
    id: 37,
    order: 4,
    display_name: "24時間ゴミ出し可",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsAnytimeGarbageOut,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_anytime_garbage_out: { equals: true },
    },
  },
  {
    id: 18,
    order: 5,
    kodawari_flg: true,
    display_name: "駐車場あり",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.ParkingStatus,
      operator: SqlOperator.In,
      value: isExistParkingValues,
    },
    v2_condition: {
      parking_status: {
        in: [ParkingStatusTypeInputEnum.Exist, ParkingStatusTypeInputEnum.NoCharge],
      },
    },
  },
  {
    id: 38,
    order: 6,
    display_name: "駐輪場あり",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsExistBicycleParking,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_exist_bicycle_parking: { equals: true },
    },
  },
  {
    id: 17,
    order: 7,
    display_name: "バイク置き場",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsExistMotorcycleStorage,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_exist_motorcycle_storage: { equals: true },
    },
  },
  {
    id: 39,
    order: 8,
    display_name: "ルーフバルコニー",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsRoofBalcony,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_roof_balcony: { equals: true },
    },
  },
  {
    id: 57,
    order: 9,
    display_name: "バルコニー・ベランダ",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsBalcony,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_balcony: { equals: true },
    },
  },
  {
    id: 58,
    order: 10,
    display_name: "都市ガス",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.GasTypeId,
      operator: SqlOperator.In,
      value: [1, 4],
    },
    v2_condition: {
      gas_type_id: { equals: GasTypeInputEnum.Pipeline },
    },
  },
  {
    id: 59,
    order: 11,
    display_name: "プロパンガス",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.GasTypeId,
      operator: SqlOperator.In,
      value: [2, 5],
    },
    v2_condition: {
      gas_type_id: { equals: GasTypeInputEnum.Cylinder },
    },
  },
  {
    id: 47,
    order: 12,
    display_name: "オール電化",
    seo_title: undefined,
    category_type: "建物設備",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsOnlyElectricSystems,
      operator: SqlOperator.Eq,
      value: true,
    },
    v2_condition: {
      is_only_electric_systems: { equals: true },
    },
  },

  /*------ 入居条件 ------*/
  {
    id: 24,
    order: 1,
    kodawari_flg: true,
    display_name: "ペット相談可",
    seo_title: "ペット可(相談可)",
    category_type: "入居条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.Pets,
      operator: SqlOperator.Eq,
      value: 3,
    },
    v2_condition: {
      pets: { equals: RentalRestrictionPetInputEnum.Allowed },
    },
  },
  {
    id: 48,
    order: 2,
    display_name: "楽器相談可",
    seo_title: undefined,
    category_type: "入居条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.MusicalInstrument,
      operator: SqlOperator.Eq,
      value: 3,
    },
    v2_condition: {
      musical_instrument: { equals: RentalRestrictionMusicalInstrumentInputEnum.Allowed },
    },
  },
  {
    id: 40,
    order: 3,
    display_name: "女性限定",
    seo_title: undefined,
    category_type: "入居条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.Gender,
      operator: SqlOperator.Eq,
      value: 3,
    },
    v2_condition: {
      gender: { equals: RentalRestrictionGenderInputEnum.WomenLimited },
    },
  },
  {
    id: 49,
    order: 4,
    display_name: "ルームシェア可",
    seo_title: undefined,
    category_type: "入居条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.RoomShare,
      operator: SqlOperator.Eq,
      value: 3,
    },
    v2_condition: {
      room_share: { equals: RentalRestrictionRoomShareInputEnum.Allowed },
    },
  },
  {
    id: 60,
    order: 6,
    display_name: "SOHO・オフィス可",
    seo_title: undefined,
    category_type: "入居条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.OfficeUse,
      operator: SqlOperator.In,
      value: [1, 3],
    },
    v2_condition: {
      office_use: { equals: RentalRestrictionOfficeUseInputEnum.Allowed },
    },
  },

  /*------ その他条件 ------*/
  {
    id: 5,
    order: 5,
    display_name: "デザイナーズ",
    seo_title: "デザイナーズ(おしゃれ物件)",
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsDesignersMansion,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_designers_mansion: { equals: true },
    },
  },
  {
    id: 21,
    order: 7,
    display_name: "タワーマンション",
    seo_title: "タワーマンション(高層マンション)",
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.NumberOfGroundFloors,
      operator: SqlOperator.Gte,
      value: 20,
    },
    v2_condition: {
      is_tower_mansion: { equals: true },
    },
  },
  {
    id: 9,
    order: 8,
    display_name: "リノベーション",
    seo_title: "リノベーション・リフォーム",
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.RenovationFlg,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      renovation_flg: { equals: true },
    },
  },
  {
    id: 4,
    order: 9,
    display_name: "分譲マンション",
    seo_title: undefined,
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.IsCondominium,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_condominium: { equals: true },
    },
  },
  {
    id: 3,
    order: 10,
    display_name: "新築",
    seo_title: undefined,
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.BuildingAge,
      operator: SqlOperator.Eq,
      value: 0,
    },
    v2_condition: {
      is_shinchiku: { equals: true },
    },
  },
  {
    id: 27,
    order: 11,
    display_name: "築浅",
    seo_title: undefined,
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      column: ConditionColumn.BuildingAge,
      operator: SqlOperator.Lt,
      value: 5,
    },
    v2_condition: {
      is_chikuasa: { equals: true },
    },
  },
  {
    id: 41,
    order: 12,
    kodawari_flg: true,
    display_name: "定期借家を含まない",
    seo_title: undefined,
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.TypeOfContract,
      operator: SqlOperator.Neq,
      value: 2,
    },
    v2_condition: {
      type_of_contract: { not: { equals: 2 } },
    },
  },
  {
    id: 22,
    order: 13,
    display_name: "低層マンション",
    seo_title: undefined,
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Building,
    conditions: {
      AND: [
        {
          column: ConditionColumn.NumberOfGroundFloors,
          operator: SqlOperator.Lte,
          value: 3,
        },
        {
          column: ConditionColumn.PropertyTypeId,
          operator: SqlOperator.Eq,
          value: 1,
        },
      ],
    },
    v2_condition: {
      is_low_rise_mansion: { equals: true },
    },
  },
  {
    id: 50,
    order: 14,
    display_name: "即入居可",
    seo_title: "即入居可(即引き渡し可)",
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.AvailableStartDateText,
      operator: SqlOperator.Eq,
      value: "即入居可",
    },
    v2_condition: {
      is_ready_to_move_in: { equals: true },
    },
  },
  {
    id: 61,
    order: 15,
    display_name: "見学可",
    seo_title: undefined,
    category_type: "その他条件",
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.IsReadyToPreview,
      operator: SqlOperator.Eq,
      value: 1,
    },
    v2_condition: {
      is_ready_to_preview: { equals: true },
    },
  },

  /* カテゴリIDが1000以上の場合は内部メンバー用の条件。選択されていない場合は非表示 */
  {
    id: 1001,
    order: 1001,
    display_name: "フリーレントキャンペーン",
    seo_title: undefined,
    is_display: true,
    entity: CategoryConditionEntity.Room,
    conditions: {
      column: ConditionColumn.AdvertisementRate,
      operator: SqlOperator.Gte,
      value: 200,
    },
    v2_condition: {
      advertisement_rate: { gte: 200 },
    },
  },
];

export const categoryConditions: CategoryCondition[] = categoryConditionProps.map(
  (c) => new CategoryCondition(c),
);
