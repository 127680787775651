import Link from "next/link";
import styles from "./Notification.module.scss";
import dayjs from "~/lib/dayjs";

const Notification: React.FunctionComponent = () => {
  const isShow = dayjs().tz().isBefore(new Date("2025/01/06"));

  return (
    <>
      {(isShow && (
        <Link
          href="https://airdoor.notion.site/acb73b3f2cd141ae84398bcf91ba3519?pvs=74"
          prefetch={false}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={styles.notificationBanner}>年末年始の営業スケジュールについて</div>
        </Link>
      )) ||
        ""}
    </>
  );
};

export default Notification;
