/**
 * フォーム種別
 */
export const FormType = {
  PreviewReservation: "preview_reservation",
  PreviewReservationForUnpreviewable: "preview_reservation_for_unpreviewable",
  VisitReservation: "visit_reservation",
  OnlineReservation: "online_reservation",
  VisitOrOnlineReservation: "visit_or_online_reservation",
  RentalApplication: "rental_application",
  OtherInquiry: "other_inquiry",
  NoListedRequest: "no_listed_request",
  ConsultationRequest: "consultation_request",
  LineInquiry: "line_inquiry",
} as const;

export type FormType = (typeof FormType)[keyof typeof FormType];

export const formTypeLabels = {
  [FormType.PreviewReservation]: "内見予約フォーム",
  [FormType.PreviewReservationForUnpreviewable]: "内見予約[退去予定or未完成]フォーム",
  [FormType.VisitReservation]: "店舗相談予約フォーム",
  [FormType.OnlineReservation]: "オンライン相談予約フォーム",
  [FormType.VisitOrOnlineReservation]: "店舗orオンライン相談予約フォーム",
  [FormType.RentalApplication]: "入居申込フォーム",
  [FormType.OtherInquiry]: "一般問い合わせフォーム",
  [FormType.NoListedRequest]: "掲載外の物件相談フォーム",
  [FormType.ConsultationRequest]: "物件提案希望フォーム",
  [FormType.LineInquiry]: "LINE問い合わせ",
} as const;
