import { ParsedUrlQuery } from "querystring";
import _ from "lodash";
import { ConditionInterface } from "../ConditionInterface";
import { getFulfilledRailways, Railway } from "~/types/Models/Railway";
import { Station } from "~/types/Models/Station";

type RouteConditionType = {
  railways: Railway[];
  stations: Station[];
};
export interface RouteConditionInterface extends ConditionInterface {
  optimizedData: RouteConditionType;
  isCanonical: boolean;
  query: ParsedUrlQuery;
  canonicalQuery: ParsedUrlQuery;
}
export abstract class AbstractRouteCondition implements RouteConditionInterface {
  abstract legacy: boolean;
  protected _sourceData: RouteConditionType;
  protected abstract setSourceData(): void;
  protected abstract toQuery(data: RouteConditionType): ParsedUrlQuery;

  private _optimizedData: RouteConditionType;
  get optimizedData(): RouteConditionType {
    if (!this._optimizedData) this.setOptimizedData();
    return this._optimizedData;
  }

  private setOptimizedData(): void {
    if (!this._sourceData) this.setSourceData();

    const fulfilledRailways = getFulfilledRailways(this._sourceData.stations.map((s) => s.id));
    const optimizedRailways = _.uniqBy([...this._sourceData.railways, ...fulfilledRailways], "id");

    // 沿線条件に設定のある駅は除外する
    const optimizedStations = _.uniqBy(
      this._sourceData.stations.filter((s) => !optimizedRailways.some((r) => r.id === s.railwayId)),
      "id",
    );

    this._optimizedData = {
      railways: _.sortBy(optimizedRailways, "id"),
      stations: _.sortBy(optimizedStations, ["railwayId", "id"]), // FIXME: 修正する see: https://www.notion.so/airdoor/stations-display_order-1843e1e1116980d5bfbed1de4852cf4d
    };
  }

  get isCanonical(): boolean {
    if (this.optimizedData.stations.length < 1) return true;
    return this.optimizedData.stations.every((s) => s.canonicalStation.id === s.id);
  }

  get hasAnyCondition(): boolean {
    return this.optimizedData.railways.length > 0 || this.optimizedData.stations.length > 0;
  }

  get variantCount(): number {
    let count = 0;
    this.optimizedData.railways.length > 0 && ++count;
    this.optimizedData.stations.length > 0 && ++count;

    return count;
  }

  get conditionCount(): number {
    return this.optimizedData.railways.length + this.optimizedData.stations.length;
  }

  get query(): ParsedUrlQuery {
    return this.toQuery(this.optimizedData);
  }

  get canonicalQuery(): ParsedUrlQuery {
    return this.toQuery(this.canonicalData);
  }

  private get canonicalData(): RouteConditionType {
    const data = {
      ...this.optimizedData,
    };

    data.stations = _.uniqBy(
      data.stations.map((s) => s.canonicalStation),
      "id",
    );

    return data;
  }
}
