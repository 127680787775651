/**
 * コンバージョン位置
 */
export const ConversionPoint = {
  DetailTop: "dt",
  DetailFooter: "df",
  FavoriteList: "fl",
  ViewHistoryList: "vhl",
  RelatedRoomListInBuilding: "rrlib",
  PickupRoom: "pr",
  Unknown: "uk",
} as const;

export type ConversionPoint = (typeof ConversionPoint)[keyof typeof ConversionPoint];

export const conversionPointLabels = {
  [ConversionPoint.DetailTop]: "詳細ページ上部",
  [ConversionPoint.DetailFooter]: "詳細ページフッター",
  [ConversionPoint.FavoriteList]: "お気に入り一覧",
  [ConversionPoint.ViewHistoryList]: "閲覧履歴一覧",
  [ConversionPoint.RelatedRoomListInBuilding]: "同一建物の部屋一覧",
  [ConversionPoint.PickupRoom]: "ピックアップ物件",
  [ConversionPoint.Unknown]: "不明",
} as const;
