import { airdoor, StationData } from "@airdoor/master-data";
import { makeRailway, Railway } from "./Railway";
import { QUERY_KEY } from "@/pages/list/Logic/Condition/QueryKey";

export type Station = {
  id: number;
  railwayId: number;
  name: string;
  displayName: string;
  legacyCode: string | undefined;
  queryKey: "st";
  query: { st: string };
  url: string;
  canonicalStation: Station;
  railway: Railway;
  nearbyStations: Station[];
  relatedStations: Station[];
};
export const getStation = (stationId: number): Station | undefined => {
  try {
    const station = airdoor.stations.get(stationId);
    return makeStation(station);
  } catch (e) {
    // 登録のない駅の可能性があるので
  }
};
export const getStationStrict = (stationId: number): Station | undefined => {
  const station = airdoor.stations.get(stationId);
  return makeStation(station);
};
export const getStationByLegacyCode = (legacyCode: string): Station => {
  const station = airdoor.legacyStations.get(legacyCode);

  return makeStation(station, legacyCode);
};

export const makeStation = (data: StationData, legacyCode?: string) => {
  legacyCode ||= airdoor.legacyStations.getLegacyCode(data.id);

  return {
    id: data.id,
    railwayId: Number(data.railwayId),
    name: data.name,
    displayName: `${data.name}駅`,
    queryKey: QUERY_KEY.STATION,
    legacyCode,
    get query() {
      return { [this.queryKey]: `d-${legacyCode}` };
    },
    get url() {
      return `/list?${new URLSearchParams(this.query).toString()}`;
    },
    get railway() {
      return makeRailway(data.railway);
    },
    get canonicalStation() {
      return makeStation(data.canonicalStation);
    },
    get nearbyStations() {
      return data.nearbyStations.map((s) => makeStation(s));
    },
    get relatedStations() {
      return data.relatedStations.map((s) => makeStation(s));
    },
  };
};
